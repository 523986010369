import ListLayout from "../../../components/layout/main/ListLayout";
import { PageCard } from "../../../styles/CommonStyles";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import { useState } from "react";
import { CacheKeys, coinTransactionOptions, coinTransactionSortOptions, coinAnalysisTransactionOptions } from "../../../core/constants/filter";
import CoinTransactionTable from "../../../components/main/reports/coin-transactions/CoinTransactionTable";
import { useWindowSize } from "../../../core/hooks/common/useWindowSize";
import { TABLET_SIZE } from "../../../core/constants/common";
import RCoinTransactionTable from "../../../components/responsive/main/reports/coin-transactions/RCoinTransactionTable";
import { useAclContext } from "../../../core/contexts/acl";
import { useGetCoinTransactions, useGetCoinTransactionAnalysis } from "../../../core/services/react-query/coining";
import Tabbar from "../../../components/common/tabs/Tabbar";
import TransactionAnalysis from "../../../components/main/transaction-analysis/TransactionAnalysis";


const CoinTransaction = () => {

    const { width } = useWindowSize()
    const [transactions, setTransactions] = useState({ data: null, loading: true })
    const [activeTab, setActiveTab] = useState(1)

    const { permissions } = useAclContext()
    const hasDownload = permissions?.export?.write

    const onQuerySuccess = (res) => {
        setTransactions(res)
    }

    const Component = width > TABLET_SIZE ? CoinTransactionTable : RCoinTransactionTable

    return (
        <ListLayout>
            <PageCard>
                <Tabbar
                    active={activeTab}
                    tabs={['coin-transactions', 'analysis']}
                    onTabClicked={(idx) => setActiveTab(idx)}
                />
                {activeTab === 1 ?
                    <FilterLayout
                        query={useGetCoinTransactions}
                        onQuerySuccess={onQuerySuccess}
                        options={coinTransactionOptions}
                        cache={CacheKeys.COIN_TRANSACTIONS}
                        hasDownload={hasDownload}
                        sortOptions={coinTransactionSortOptions}
                    >
                        {width &&
                            <Component
                                data={transactions}
                            />
                        }
                    </FilterLayout>
                    :
                    <FilterLayout
                        query={useGetCoinTransactionAnalysis}
                        onQuerySuccess={onQuerySuccess}
                        options={coinAnalysisTransactionOptions}
                        cache={CacheKeys.COIN_TRANSACTION_ANALYSIS}
                        extra={{ coin: 'usdt' }}
                    >
                        <TransactionAnalysis
                            type={'coin'}
                            data={transactions}
                        />
                    </FilterLayout>
                }

            </PageCard>
        </ListLayout>
    )
}

export default CoinTransaction;
