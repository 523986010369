import {
    LtrSideOpenIcon,
    RtlSideOpenIcon,
    SideItem,
    SideWrapper,
    SideSubItem,
    SideItemWrapper,
    OpenCloseWrapper,
    OpenCloseBtn,
    SideHolder, ExitIcon,
    CountTag
} from "../../../styles/layout/sidebar";
import { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom'
import { CFlex, DText, Flex, IconWrapper } from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import { useMainContext } from "../../../core/contexts/main";
import Tooltip from "../../common/utils/Tooltip";
import Skeleton from "../../../core/packages/skeleton";
import SidebarSkeleton from "../../skeleton/SidebarSkeleton";
import useSidebar from "../../../core/hooks/layout/useSidebar";
import { getRandomColor } from "../../../core/utils/theme";
import useClickOutside from "../../../core/hooks/common/useClickOutside";
import { useWindowSize } from "../../../core/hooks/common/useWindowSize";
import { MOBILE_SIZE } from "../../../core/constants/common";
import { useLogout } from "../../../core/services/react-query/auth";
import { useGetBankAccounts } from "../../../core/services/react-query/bank-accounts";



const Sidebar = () => {

    const { width } = useWindowSize()
    const location = useLocation()
    const { lang, sidebar, onSidebarChange } = useMainContext()

    const { mutate: logout } = useLogout()

    const sidebarRef = useRef()
    useClickOutside(sidebarRef, () => {
        if (sidebar === 'desktop' && width < MOBILE_SIZE) {
            onSidebarChange()
        }
    })

    const routeRefs = useRef([])

    const [activeRoute, setActiveRoute] = useState()
    const { routes, onRouteClicked, onSubRouteClicked } = useSidebar()

    useEffect(() => {
        setActiveRoute(location?.pathname)
    }, [location])

    const subrouteActive = (item) => {
        return item.open === false && item.title === activeRoute.split('/')[1]
    }

    const { data: bankAccounts } = useGetBankAccounts({}, {})
    const bankAccountsCount = bankAccounts?.meta?.total

    return (
        <SideHolder
            ref={sidebarRef}
        >
            <OpenCloseWrapper
                onClick={onSidebarChange}
            >
                <OpenCloseBtn
                    size={22}
                    status={sidebar}
                />
            </OpenCloseWrapper>
            <SideWrapper status={sidebar}>
                <CFlex fw fh justify={'space-between'}>
                    <CFlex fw align='flex-start' justify='flex-start'>
                        {routes?.length ?
                            routes.map((item, idx) => {
                                if (!item.show) return <></>

                                const hasItems = item.items
                                const Icon = item.icon

                                return (
                                    <SideItemWrapper key={item.path} open={item.open} status={sidebar}>
                                        <Tooltip
                                            disabled={sidebar === 'desktop'}
                                            content={item.title}
                                            placement={lang === 'en' ? 'right' : 'left'}
                                        >
                                            <SideItem
                                                ref={el => routeRefs[idx] = el}
                                                status={sidebar}
                                                active={activeRoute === item.path || subrouteActive(item)}
                                                onClick={() => onRouteClicked(idx)}
                                                giga={hasItems}
                                            >
                                                {hasItems ? (
                                                    lang === 'en' ?
                                                        <LtrSideOpenIcon open={item.open} size={26} />
                                                        :
                                                        <RtlSideOpenIcon open={item.open} size={26} />
                                                )
                                                    :
                                                    <Icon
                                                        data-tip
                                                        data-for={item.title}
                                                        style={{ margin: '0 6px' }}
                                                        color={getRandomColor(idx)}
                                                        size={24}
                                                    />
                                                }
                                                {sidebar === 'desktop' &&
                                                    <Text tid={item.title} />
                                                }
                                                {item.title === 'bank-accounts' ?
                                                    <CountTag sidebar={sidebar}>
                                                        <span>{bankAccountsCount || 0}</span>
                                                    </CountTag>
                                                    : null}
                                            </SideItem>
                                        </Tooltip>

                                        {(hasItems && item.open) &&
                                            item.items.map((subroute, index) => {

                                                if (!subroute.show) return <></>
                                                const SubIcon = subroute.icon
                                                return (
                                                    <Tooltip
                                                        key={subroute.title}
                                                        disabled={sidebar === 'desktop'}
                                                        content={subroute.title}
                                                        placement={lang === 'en' ? 'right' : 'left'}
                                                    >
                                                        <SideSubItem
                                                            status={sidebar}
                                                            onClick={() => onSubRouteClicked(idx, index)}
                                                            active={activeRoute === subroute.path}
                                                        >
                                                            <SubIcon
                                                                style={{ margin: '0 6px' }}
                                                                color={getRandomColor(index)}
                                                                size={20}
                                                            />
                                                            {sidebar === 'desktop' &&
                                                                <Text tid={subroute.title} />
                                                            }
                                                        </SideSubItem>
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </SideItemWrapper>
                                )
                            })
                            :
                            <Skeleton
                                count={4}
                                Content={SidebarSkeleton}
                            />
                        }
                    </CFlex>
                    <Flex fw>
                        <Tooltip
                            disabled={sidebar === 'desktop'}
                            content={'exit'}
                            placement={lang === 'en' ? 'right' : 'left'}
                        >
                            <IconWrapper onClick={logout}>
                                <ExitIcon size={24} />
                                {sidebar === 'desktop' &&
                                    <DText color={'#e9106c'}>
                                        <Text tid={'exit'} />
                                    </DText>
                                }
                            </IconWrapper>
                        </Tooltip>
                    </Flex>
                </CFlex>
            </SideWrapper>
        </SideHolder>

    )
}

export default Sidebar
