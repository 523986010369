import { useAclContext } from "../../../contexts/acl";
import { useState } from "react";
import { bankAccountOperations } from "../../../constants/operation";
import { useDeleteBankAccount } from "../../../services/react-query/user";


const useBankAccounts = () => {

    const { permissions } = useAclContext()
    const hasWriteAccess = permissions?.user?.write

    const actionInitial = {
        show: false,
        action: null,
        bank: null
    }
    const [actionMode, setActionMode] = useState(actionInitial)
    const closeActionMode = () => setActionMode(actionInitial)

    const deleteModalInitial = { open: false, bank: null }
    const [deleteModal, setDeleteModal] = useState(deleteModalInitial)
    const closeDeleteModal = () => setDeleteModal(deleteModalInitial)

    const { mutate: onDeleteModal, isLoading: deleteLoading } = useDeleteBankAccount(() => {
        closeDeleteModal()
    })
    const onSubmitDelete = () => {
        onDeleteModal({
            userId: deleteModal.bank?.bank?._id,
            bankId: deleteModal.bank?.id
        })
    }

    const onOptionClicked = (idx, bank, bankUser) => {
        if (idx === 2) {
            // delete bank
            setDeleteModal({ open: true, bank })
        } else {
            // accept / reject bank
            setActionMode({
                show: true,
                action: bankAccountOperations[idx],
                bank,
                bankUser
            })
        }
    }

    return {
        hasWriteAccess,
        actionMode,
        closeActionMode,
        onOptionClicked,
        deleteModal,
        closeDeleteModal,
        deleteLoading,
        onSubmitDelete

    }
}

export default useBankAccounts
