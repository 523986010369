import { useMemo } from "react"
import Text from "../../../core/utils/Text"
import { formatNumber } from "../../../core/utils/common"
import { Grid, Row } from "../../../styles/CommonStyles"
import { Fragment } from "react"
import styled from "styled-components"



const TransactionAnalysis = ({ type, data }) => {

    const onBoard = useMemo(() => {
        const temp = {}

        let deposit = { count: 0, amount: 0 }
        let withdraw = { count: 0, amount: 0 }
        try {
            if (!!data?.data?.data[0]) deposit = data?.data?.data[0]
            if (!!data?.data?.data[1]) withdraw = data?.data?.data[1]
        } catch (_) { }

        const diff = deposit.amount - withdraw.amount
        const coin = type === 'bank' ? 'irt' : deposit.coin
        temp['deposit-amount'] = {
            value: `${formatNumber(deposit.amount, { type: coin })} ${coin?.toUpperCase() || ''}`,
        }
        temp['deposit-count'] = { value: deposit.count, coin }
        temp['withdraw-amount'] = {
            value: `${formatNumber(withdraw.amount, { type: coin })} ${coin?.toUpperCase() || ''}`,
        }
        temp['withdraw-count'] = { value: withdraw.count, coin }
        temp['income-total'] = {
            value: `${formatNumber(diff, { type: coin })} ${coin?.toUpperCase() || ''}`,
            coin,
            color: diff < 0 ? 'rgb(239 68 68)' : 'rgb(74 222 128)'
        }
        return temp
    }, [data, type])

    console.log(onBoard)



    return (
        <Row cs={'50% 50%'} style={{ maxWidth: '600px' }}>
            {Object.keys(onBoard).map(k => {

                return (
                    <Fragment key={k}>
                        <Text tid={k} />
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <span dir={'ltr'} style={{ color: onBoard[k].color && onBoard[k].color }}>
                                {onBoard[k]?.value}
                            </span>
                        </div>
                        <Line />
                    </Fragment>
                )
            })}
        </Row>
    )
}

const Line = styled.div`
    grid-column: span 2 / span 2;
    width: 100%;
    height: 1px;
    background-color: #fafafa20;
`


export default TransactionAnalysis