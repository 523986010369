import {UserDetailListWrapper} from "../../../../styles/main/user";
import {CacheKeys, userTraceFilterOptions} from "../../../../core/constants/filter";
import FilterLayout from "../../../layout/filter/FilterLayout";
import {useAclContext} from "../../../../core/contexts/acl";
import {useParams} from "react-router-dom";
import {useState} from "react";
import TraceTable from "../../reports/trace/TraceTable";
import {useWindowSize} from "../../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../../core/constants/common";
import RTraceTable from "../../../responsive/main/reports/trace/RTraceTable";
import {useGetCoinTrace} from "../../../../core/services/react-query/report/trace";


const UserTrace = () => {

    const { width } = useWindowSize()
    const params = useParams()
    const [traces, setTraces] = useState({loading: true, data: null})
    const onQuerySuccess = res => setTraces(res)
    const { permissions } = useAclContext()
    const hasDownload = permissions?.export?.write

    const ActiveComp = width > TABLET_SIZE ? TraceTable : RTraceTable

    return (
        <UserDetailListWrapper>
            <FilterLayout
                query={useGetCoinTrace}
                options={userTraceFilterOptions}
                onQuerySuccess={onQuerySuccess}
                cache={CacheKeys.USER_TRACE}
                extra={{ 'userId': params.id }}
                hasDownload={hasDownload}
            >
                <ActiveComp data={traces} fromUser />
            </FilterLayout>
        </UserDetailListWrapper>
    )
}

export default UserTrace
