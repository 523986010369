import {useLocation, useNavigate, useParams} from "react-router-dom"
import { SOCKET_URL } from "../../../../../core/constants/urls"
import {formatName, formatNumber, getNames} from "../../../../../core/utils/common"
import Text from "../../../../../core/utils/Text"
import { CMargin, DText, Flex, Row } from "../../../../../styles/CommonStyles"
import Operation from "../../../../common/utils/Operation"
import UserLink from "../../../../common/utils/UserLink"
import RTableLayout from '../../../layout/RTableLayout'
import {MiniLineBreak, RespIndex} from '../../../../../styles/main/MainCommonStyles'
import { getTradeTypeColor } from "../../../../../core/utils/theme"
import { TradeTypeBadge } from "../../../../../styles/main/orders"
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex"

const RTraceTable = ({
    data,
    fromUser = false
}) => {

    const params = useParams()
    const { data: traces } = data
    const location = useLocation()
    const navigate = useNavigate()

    const { getTableIndex } = useGetTableIndex()

    const onDetailsClicked = (trace) => {
        if (fromUser)
            navigate(`/users/${params.id}/${params.tab}/${trace?._id}`)
        else {
            const path = location.pathname
            navigate(`${path}/${trace._id}`)
        }
    }

    return (
        <RTableLayout
            data={data}
        >
            {traces?.data?.map((trace, idx) => {

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(
                    trace.user?.firstName,
                    trace.user?.lastName
                )


                return (
                    <Row cs={'100%'} index={idx} key={trace._id}>
                        <Flex justify='space-between'>
                            <Flex>
                                <img
                                    src={SOCKET_URL + `assets/icon/${trace.currency}.png`}
                                    alt={' '}
                                    width={'24px'}
                                />
                                <DText main style={{ margin: '0 8px' }}>
                                    {trace.currency?.toUpperCase()}
                                </DText>
                            </Flex>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(trace)}
                                hasDetails
                            />
                        </Flex>
                        <CMargin margin='8px' />
                        <Flex justify='space-between'>
                            <DText main fontSize='s'>
                                <Text tid='name' /> :
                            </DText>
                            <UserLink
                                _id={trace.userId}
                                name={formatName(FULLNAME)}
                            />
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify='space-between'>
                            <DText main fontSize='s'>
                                <Text tid='volume' /> :
                            </DText>
                            <DText primary fontSize='s'>
                                {formatNumber(trace.volume)}
                            </DText>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify='space-between'>
                            <DText main fontSize='s'>
                                <Text tid='wages' /> :
                            </DText>
                            <DText primary fontSize='s'>
                                {formatNumber(trace.wage)}
                            </DText>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify='space-between'>
                            <DText main fontSize='s'>
                                <Text tid='cause' /> :
                            </DText>
                            <TradeTypeBadge color={getTradeTypeColor(trace.cause?.label)}>
                                <Text tid={trace?.cause?.label} />
                            </TradeTypeBadge>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify='space-between'>
                            <DText main fontSize='s'>
                                <Text tid='action' /> :
                            </DText>
                            <TradeTypeBadge color={getTradeTypeColor(trace.cause?.action)}>
                                <Text tid={trace?.cause?.action ? trace?.cause?.action : trace?.cause?.label} />
                            </TradeTypeBadge>
                        </Flex>
                        <CMargin margin='6px' />
                        <RespIndex>
                            {getTableIndex(idx)}
                        </RespIndex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}


export default RTraceTable
