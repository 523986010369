import ListLayout from "../../../components/layout/main/ListLayout";
import {Column, PageCard, Relative, Row} from "../../../styles/CommonStyles";
import TableLayout from "../../../components/layout/main/TableLayout";
import {useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../../core/services/fetch-api/get";
import {useState} from "react";
import {formatNumber} from "../../../core/utils/common";
import {TradeTypeBadge} from "../../../styles/main/orders";
import DateTime from "../../../components/common/utils/DateTime";
import Operation from "../../../components/common/utils/Operation";
import {bankAccountOperations} from "../../../core/constants/operation";


const useGetAllStore = (params) => {

    return useQuery('get-all-store', () => filterFetch(params, 'store'), { select: res => res?.data })
}

const Payments = () => {
    const headers = [
        { title: 'fullname' }, { title: 'cardNo' },
        { title: 'productPrice' }, { title: 'currency' },
        { title: 'country' }, { title: 'type' },
        { title: 'date' }
    ]
    const cs = '5% 15% 15% 10% 10% 10% 10% 10% 15%'

    const [page] = useState(1)
    const { data } = useGetAllStore({ page })

    return (
        <ListLayout>
            <PageCard>
                <TableLayout
                    headers={headers}
                    cs={cs}
                    data={{ data, loading: false }}
                >
                    {data?.data?.map((item, idx) => {

                        return (
                            <Relative>
                                <Row key={item._id} cs={cs}>
                                    <Column>{idx + 1}</Column>
                                    <Column>
                                        <span>{`${item.firstName} ${item.lastName}`}</span>
                                    </Column>
                                    <Column>
                                        <span>{item.cardNo}</span>
                                    </Column>
                                    <Column>
                                        <span>{formatNumber(item.productPrice)}</span>
                                    </Column>
                                    <Column>
                                        <span>{item.currency.id}</span>
                                    </Column>
                                    <Column>
                                        <span>{item.country}</span>
                                    </Column>
                                    <Column>
                                        <TradeTypeBadge>
                                            <span>{item.type}</span>
                                        </TradeTypeBadge>
                                    </Column>
                                    <Column>
                                        <DateTime dt={item.createdAt} />
                                    </Column>
                                </Row>
                                <Column operation style={{top: '40%', left: '20px'}}>
                                    <Operation
                                        options={['accept', 'reject', 'refine']}
                                        onOptionClicked={() => {}}
                                        hasWriteAccess
                                    />
                                </Column>
                            </Relative>

                        )
                    })}
                </TableLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Payments
