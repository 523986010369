import { useMutation, useQuery } from "react-query"
import { useMainContext } from "../../../../contexts/main"
import { useQueryContext } from "../../../../contexts/query"
import { makeQueryFilters } from "../../../../utils/query-maker"
import { filterFetch, normalFetch } from "../../../fetch-api/get"
import { putApi } from "../../../fetch-api/put"


export const BANKING_KEYS = {
    GET_BANK_TRANSACTIONS: 'get-bank-transactions',
    UPDATE_BANK_TRANSACTION: 'update-bank-transaction',
    GET_A_TRANSACTION: 'get-a-bank-transaction',
    GET_BANK_CONFIG: 'get-bank-config'
}

const useGetBankTransactions = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = { ...extra, ...filters }
    const params = { ...makeQueryFilters(newFilters, lang), ...pagination }
    if (csv) {
        params.export = 'csv'
    }

    return useQuery(
        BANKING_KEYS.GET_BANK_TRANSACTIONS, () => filterFetch(params, 'banking/admin'),
        {
            cacheTime: 0
        }
    )
}

export const useGetBankTransactionAnalysis = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = { ...extra, ...filters }
    const params = { ...makeQueryFilters(newFilters, lang), ...pagination }
    if (csv) {
        params.export = 'csv'
    }

    return useQuery(
        BANKING_KEYS.GET_BANK_TRANSACTIONS, () => filterFetch(params, 'banking/admin/analysis'),
        {
            cacheTime: 0
        }
    )

}


const useUpdateBankTransaction = (onSuccess) => {

    const { queryClient } = useQueryContext()

    return useMutation(
        BANKING_KEYS.UPDATE_BANK_TRANSACTION, (data) => putApi(data.data, `banking/${data.id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(BANKING_KEYS.GET_BANK_TRANSATIONS)
                onSuccess && onSuccess()
            }
        }
    )
}

const useGetABankTransaction = (id) => {

    return useQuery(
        BANKING_KEYS.GET_A_TRANSACTION, () => normalFetch(`banking/${id}`),
        {
            select: res => res?.data?.data
        }
    )
}


const useGetBankingConfig = () => {

    return useQuery(
        BANKING_KEYS.GET_BANK_CONFIG, () => normalFetch('configs/banking'),
        {
            select: (res) => res?.data?.data
        }
    )
}


export {
    useGetBankTransactions,
    useUpdateBankTransaction,
    useGetABankTransaction,
    useGetBankingConfig
}
