import ListLayout from "../../../components/layout/main/ListLayout";
import WagesTable from "../../../components/main/reports/wages/WagesTable";
import RWagesTable from "../../../components/responsive/main/reports/wages/RWagesTable";
import { TABLET_SIZE } from "../../../core/constants/common";
import { useWindowSize } from "../../../core/hooks/common/useWindowSize";
import { PageCard } from "../../../styles/CommonStyles";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {useGetWagesTrace} from "../../../core/services/react-query/report/trace";
import {CacheKeys, coinTransactionOptions, wageTraceOptions} from "../../../core/constants/filter";
import {useState} from "react";



const WagesList= () => {

    const [wages, setWages] = useState({ data: null, loading: false })
    const onQuerySuccess = res => setWages(res)

    const { width } = useWindowSize()
    const Component = width > TABLET_SIZE ? WagesTable : RWagesTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetWagesTrace}
                    options={wageTraceOptions}
                    onQuerySuccess={onQuerySuccess}
                    cache={CacheKeys.TRACE_WAGES}
                >
                    {width && <Component data={wages} />}
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default WagesList;
